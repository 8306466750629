import styles from './HeaderBanner.module.css';

export default function HeaderBanner() {
    return (
        <div className={styles.headerBannerWrapper}>
            <div className={styles.content}>
                <h1>Підготовка до НМТ у Запоріжжі</h1>
                <h3>Підготовка до НМТ на курсах НУ «Запорізька політехніка» передбачає поглиблене вивчення предметів, необхідних для складання зовнішнього незалежного оцінювання. Навчальні групи формуються за оптимальною кількістю слухачів з метою досягнення найкращих результатів</h3>
                <a className={styles.regButton} href='#registration'><span>Записатися на курси</span></a>
            </div>
            <img src="./images/bigLogo.png" alt=""/>
        </div>
    )
}