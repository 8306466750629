import styles from './Format.module.css';

export default function Format() {
    return (
        <div className={styles.formatWrapper}>
            <h2>Формат навчання</h2>
            <div className={styles.generalInfo}>
                <span className={styles.name}>Загальна інформація</span>
                <div className={styles.detailsWrapper}>
                    <div className={styles.details}>
                        <span className={styles.name}>Розклад</span>
                        <div className={styles.items}>
                            <span>1 раз на тиждень</span>
                            <span>3 години</span>
                        </div>
                    </div>
                    <div className={styles.details}>
                        <span className={styles.name}>Тривалість</span>
                        <div className={styles.items}>
                            <span>набір лютого:</span>
                            <span>4 місяці</span>
                        </div>
                    </div>
                    <div className={styles.details}>
                        <span className={styles.name}>Час</span>
                        <div className={styles.items}>
                            <span>16:30 — 19:20</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}