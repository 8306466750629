import styles from './DefaultInformationBoard.module.css';
import DefaultInformationItem from "../DefaultInformationItem";

export default function DefaultInformationBoard() {
    return (
        <div className={styles.defaultInformationBoardWrapper}>
            <DefaultInformationItem header='Тривалість' info='4 місяці'/>
            <DefaultInformationItem header='Розклад' info='раз на тиждень' info2='3 години'/>
            <DefaultInformationItem header='Ціна' info='від 1500 грн'/>
            <DefaultInformationItem header='Перспективи' info='додаткові бали при вступі*'/>
        </div>
    )
}