import styles from './TelegramAlert.module.css';
import {useState} from "react";

export default function TelegramAlert() {
    const [show, setShow] = useState(true);

    if(show)
    return (
        <div className={styles.telegramWindow}>
            <span className={styles.close} onClick={() => setShow(false)}>x</span>
            <h4>«Вступ 2025» у Telegram!</h4>
            <a href="https://t.me/nuzp_2025" target='_blank' className={styles.telegramButton}>Доєднатися</a>
        </div>
    )
}