import styles from './Courses.module.css';
import CourseItem from "../CourseItem";

const courses = [
    {name: 'Математика', color: '#FFDA59'},
    // {name: 'Фізика', color: '#CFAAFF'},
    // {name: 'Українська література', color: '#CFAAFF'},
    {name: 'Українська мова', color: '#D1F2FF'},
    {name: 'Англійська мова', color: '#A2E8A9'},
    {name: 'Історія України', color: '#FFB0A7'},
    // {name: 'Географія', color: '#FFB0A7'},
    // {name: 'Біологія', color: '#FFDA59'},
    {name: 'Рисунок та композиція', color: '#D1F2FF'},
];

export default function Courses() {
    return (
        <div className={styles.coursesWrapper}>
            <h2>Предмети</h2>
            <div className={styles.items}>
                {
                    courses.map((course, i) => {
                        return <CourseItem course={course} index={i+1} key={i}/>
                    })
                }
                <CourseItem course={{name: '', color: '#F2F2F2'}} key={'111'}/>
            </div>
        </div>
    )
}