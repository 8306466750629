import styles from './Legal.module.css';

export default function Legal() {
    return (
        <div className={styles.legalWrapper}>
            <h2>Юридична інформація</h2>
            <div className={styles.infoWrapper}>
                <div className={styles.column}>
                    <span className={styles.header}>Реквізити до оплати</span>
                    <div className={styles.item}>
                        <span className={styles.name}>Отримувач</span>
                        <span className={styles.info}>Національний університет «Запорізька політехніка»</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Код ЄДРПОУ</span>
                        <span className={styles.info}>02070849</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Рахунок</span>
                        <span className={styles.info}>UА148201720313251002201000116</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Банк</span>
                        <span className={styles.info}>ДКСУ м. Київ</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Призначення</span>
                        <span className={styles.info}>В призначенні платежу зазначити «КЕКД 25010100, плата за підготовчі курси, ПІБ слухача, індивідуальний податковий номер (ІПН)»</span>
                    </div>
                    <div className={styles.warn}>
                        <b>Важливо! Оплата здійснюється ПІСЛЯ формування груп, про що буде повідомлено окремо</b>
                    </div>
                </div>
                <div className={styles.column}>
                    <span className={styles.header}>Необхідні документи</span>
                    <div className={styles.item}>
                        <span className={styles.info}>Паспорт</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.info}>Ідентифікаційний код</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.info}>Квитанція про оплату</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.desc}>Документи подаються у вигляді копій</span>
                    </div>
                </div>
            </div>
            <div className={styles.infoWrapper}>
                <div className={styles.column}>
                    <span className={styles.header}>Контакти</span>
                    <div className={styles.item}>
                        <span className={styles.name}>Адреса</span>
                        <span className={styles.info}>м. Запоріжжя, вул. Жуковського, буд. 64, ауд. 149</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Телефон</span>
                        <span className={styles.info}><a href="tel:0989947469">098 994 74 69</a></span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Пошта</span>
                        <span className={styles.info}>nuzp.courses@gmail.com</span>
                    </div>
                </div>
            </div>
        </div>
    )
}