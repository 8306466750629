import styles from './GroupItem.module.css';

export default function GroupItem({group}) {
    const {name, col, price, priceDesc} = group;

    return (
        <div className={styles.groupItemWrapper}>
            <span className={styles.name}>{name}</span>
            <div className={styles.details}>
                <span className={styles.name}>Кількість людей</span>
                <span className={styles.info}>{col}</span>
            </div>
            <div className={styles.details}>
                <span className={styles.name}>Вартість</span>
                <span className={styles.info}>{price}</span>
            </div>
            <span className={styles.moreInfo}>{priceDesc}</span>
            <a className={styles.button} href='#registration'><span>Залиш заявку</span></a>
        </div>
    )
}