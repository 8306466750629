import styles from './Benefits.module.css';
import BenefitItem from "../BenefitItem";

const benefits = [
    {name: 'Практичний підхід', text: '90% практики з різними типами завдань та заняття без нудних часових лекцій', icon: './images/benefits/5.png'},
    {name: 'Актуальні знання', text: 'Сучасний підхід до вивчення програми ЗНО', icon: './images/benefits/2.png'},
    {name: 'Персональні завдання', text: 'Вступне тестування, формування індивідуальних завдань, контроль засвоєних знань та домашні тести', icon: './images/benefits/3.png'},
    {name: 'Успішне складання ЗНО + додаткові 10 балів при вступі', text: 'По завершенню курсів кожен слухач отримає сертифікат, який гарантує додаткові бали при вступі на технічні спеціальності', icon: './images/benefits/6.png'},
    {name: 'Заняття в групах', text: 'Невелика кількість людей в групі, замість потокового підходу, дозволяє приділити увагу кожному слухачу', icon: './images/benefits/1.png'},
    {name: 'Зворотний зв\'язок', text: 'Постійне повторення пройденого матеріалу, аналіз домашніх тестів. Можливість поставити питання за темою', icon: './images/benefits/4.png'},
]

export default function Benefits() {
    return (
        <div className={styles.benefitsWrapper}>
            <h2>Що на вас чекає</h2>
            <div className={styles.items}>
                {
                    benefits.map((benefit, i) => {
                        return <BenefitItem benefit={benefit}/>
                    })
                }
            </div>
        </div>
    )
}