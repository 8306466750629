import './App.css';
import TopHeader from './components/TopHeader';
import HeaderBanner from "./components/HeaderBanner";
import DefaultInformationBoard from "./components/DefaultInformationBoard";
import Courses from "./components/Courses";
import Benefits from "./components/Benefits";
import Format from "./components/Format";
import Groups from "./components/Groups";
import Legal from "./components/Legal";
import Registration from "./components/Registration";
import Footer from "./components/Footer";
import TelegramAlert from "./components/TelegramAlert";

function App() {
  return (
    <div className="App">
        <div className="content">
            <TelegramAlert/>
            <TopHeader/>
            <HeaderBanner/>
            <DefaultInformationBoard/>
            <Courses/>
            <Benefits/>
            <Format/>
            <Groups/>
            <Legal/>
            <Registration/>
      </div>
        <Footer/>
    </div>
  );
}

export default App;
