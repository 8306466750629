import styles from './Footer.module.css';

export default function Footer() {
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.column}>
                <img src="./images/logoWhite.png" alt=""/>
                <div className={styles.linksWrapper}>
                    <span className={styles.name}>Посилання</span>
                    <a className={styles.link} href='https://zp.edu.ua/'>Сайт університету</a>
                    <a className={styles.link} href='https://pk.zp.edu.ua/'>Приймальна комісія</a>
                </div>
                <div className={styles.linksWrapper}>
                    <span className={styles.name}>Контакти</span>
                    <span className={styles.link}>098 994 74 69</span>
                    <span className={styles.link}>nuzp.courses@gmail.com</span>
                    <span className={styles.link}>вул. Жуковскього, 64, 149</span>
                </div>
            </div>
            <div className={styles.column}>
                <div className={styles.smWrapper}>
                    <a href="https://www.facebook.com/nuzaporizkapolitekhnika" target='_blank'>
                        <img src="./images/fb.png" alt=""/>
                    </a>
                    <a href="https://instagram.com/nuzp_official" target='_blank'>
                        <img src="./images/insta.png" alt=""/>
                    </a>
                    <a href="https://t.me/nuzp_2023" target='_blank'>
                        <img src="./images/telegram.png" alt=""/>
                    </a>
                </div>
            </div>
        </div>
    )
}