import styles from './CourseItem.module.css';

export default function CourseItem({course, index}) {
    const {name, color} = course;
    return (
        <div className={styles.courseItemWrapper} style={{background: color}}>
            {
                name ? <div className={styles.index}>
                    <span>{index}</span>
                </div> : ''
            }
            <span>{name}</span>
        </div>
    )
}