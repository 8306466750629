import styles from './BenefitItem.module.css';

export default function BenefitItem({benefit}) {
    const {name, text, icon} = benefit;
    return (
        <div className={styles.benefitItemWrapper}>
            <img src={icon} alt=""/>
            <span className={styles.name}>{name}</span>
            <span className={styles.text}>{text}</span>
        </div>
    )
}