import styles from './DefaultInformationItem.module.css';

export default function DefaultInformationItem({header, info, info2, info3}) {
    return (
        <div className={styles.informationItemWrapper}>
            <span className={styles.header}>{header}</span>
            <span className={styles.info}>{info}</span>
            {
                info2 ? <span className={styles.info}>{info2}</span> : ''
            }
            {
                info3 ? <span className={styles.info}>{info3}</span> : ''
            }
        </div>
    )
}